import { createGlobalStyle } from 'styled-components';

// http://chir.ag/projects/name-that-color/
export const colors = {
  brandColor: '#02FF8C',
  dark: '#232324',
  light: '#F9F9FB',
  gray: '#C4C4C4',
  textLite: '#A8B1BD',
  backgroundLite: '#E2E2E2',
};

export default {
  primary: colors.brandColor,
  secondary: colors.dark,
  fontLight: colors.light,
  fontDark: colors.gray,
  textLite: colors.textLite,
  backgroundLite: colors.backgroundLite,
};

export const GlobalStyle = createGlobalStyle`
  body {
		margin: 0;
    padding: 0;
  }
  .linear-gradient-bg {
    background: linear-gradient(
      135deg,
    #80ffc6 0%,
    #80ffc6 6.25%,
    ${colors.brandColor} 68.64%,
    ${colors.brandColor} 81.77%,
    ${colors.brandColor} 100%
    );
  }
  .linear-gradient-bg-dark {
    background: linear-gradient(
      180deg,
    #02FF8C 0%,
    #02FF8C 6%,
    #00A359 68.64%,
    #00A359 81.77%,
    #00A359 100%
    );
  }
`;
