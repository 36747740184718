const config = {
  debug: process.env.NODE_ENV === 'development',
  siteName: process.env.NEXT_PUBLIC_ENV_SITE_NAME || 'The Fore',
  projectKey: 'the-fore',
  graphQlUri: 'https://fore-api.fly.dev/',
  // graphQlUri: 'https://fore-api-dev.fly.dev',
  graphQlUriDev: 'https://fore-api.fly.dev/',
  // graphQlUriDev: 'https://fore-api.fly.dev',
  // graphQlUriDev: 'https://fore-api-dev.fly.dev',

  agoraAppId: '7d655789cb314e20ba458aaa5317536c',
  stripeConnectId: process.env.REACT_APP_STRIPE_CONNECT_ID || '',
  email: 'support@the-fore.co',
  telephone: '+1123123123',
  location: '7480 Mockingbird Hill',
  address: 'London, UK',
  currency: '£',
};

export default config;
