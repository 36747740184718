import App from 'next/app';
import Head from 'next/head';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ZeiqProvider } from '@zeiq/web';
import { StoreProvider } from 'easy-peasy';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloProvider } from '@apollo/client/react';
import '@juggle/resize-observer';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'tailwindcss/tailwind.css';

import withReduxStore from '../utils/with-redux-store';
import apolloClient from '../utils/apolloClient';
import theme, { GlobalStyle } from '../utils/theme';
import config from '../utils/config';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class MyApp extends App {
  render() {
    const { Component, pageProps, reduxStore } = this.props;

    return (
      <ZeiqProvider value={{ theme }}>
        <ThemeProvider theme={theme}>
          <StoreProvider store={reduxStore}>
            <ApolloProvider client={apolloClient}>
              <Head>
                <title>{config.siteName}</title>
                <link rel="icon" href="/favicon.ico" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                  rel="preconnect"
                  href="https://fonts.gstatic.com"
                  crossOrigin="true"
                />
                <link
                  href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"
                  rel="stylesheet"
                />
              </Head>
              <link
                href="https://cdn.syncfusion.com/ej2/material.css"
                rel="stylesheet"
                type="text/css"
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
                rel="stylesheet"
              />
              <Component {...pageProps} />
              <GlobalStyle />
            </ApolloProvider>
          </StoreProvider>
        </ThemeProvider>
      </ZeiqProvider>
    );
  }
}

export default withReduxStore(MyApp);
